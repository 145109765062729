import {Accordion} from "react-bootstrap";
import "./Accordion.css"

export default function AccordionFaq() {
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Who is Baker International Insurance</Accordion.Header>
                    <Accordion.Body>
                        Baker International Insurance agency was created for the exclusive purpose of providing high
                        quality
                        claim service and to design “All Risk” replacement cost moving insurance policies to protect
                        household goods during local, intrastate, interstate, or international relocations. Coverage for
                        these policies are underwritten on a worldwide basis by A++ Superior insurance companies.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Valuation vs. Insurance</Accordion.Header>
                    <Accordion.Body>
                        The most important feature to know about valuation is: VALUATION IS NOT INSURANCE. Valuation
                        offered
                        by van line carriers is carrier contractual liability based on the weight of your household
                        goods,
                        and established by either the Department of Transportation (DOT) or a State agency/organization.

                        Below are options offered by most van line carriers:

                        1. $0.60 per pound per article – Limited contractual liability based on a DOT or State tariff
                        requirement. This limits the moving company from paying out the actual value of an article, in
                        the
                        event of loss or damage. It allows them to pay a minimal amount on damage or catastrophic loss
                        based
                        entirely on the weight of the item itself.

                        2. Replacement Cost Protection – A higher level of protection available for an additional
                        charge.
                        The van line or forwarder will require the purchase of a minimum amount for this protection,
                        based
                        on the weight of the household goods shipment.


                        Disadvantages of Valuation:
                        • Regardless of the valuation plan you choose, loss or damage to your goods will only be paid if
                        the
                        carrier is liable.
                        • It does not cover any loss or damage due to Acts of God, high winds, earthquakes, tornadoes,
                        hurricanes, riots, strikes, civil commotion, etc. (refer to the Bill of Lading for greater
                        details
                        on standard valuation exclusions).
                        • The van line or forwarder that damaged your household goods settles its own claims.
                        • The claim adjustment process is not standardized.
                        • Replacement Cost Valuation (which amounts to a simple increase in basic liability) typically
                        costs
                        more than insurance.
                        • Replacement Cost Valuation will not provide adequate financial protection against serious loss
                        or
                        damage. It was designed to remedy small routine claims. RCV may leave you inadequately
                        protected.

                        Advantages of Insurance:
                        • A Certificate of Insurance is issued to each customer specifying the terms and conditions of
                        the
                        “All Risk” Insurance policy, which provides comprehensive protection for household goods against
                        loss or damage. The policy includes Replacement Cost Coverage with several deductible options to
                        reduce your cost.
                        • A Certified Property and Casualty claim adjuster processes each claim in the same manner,
                        regardless of van line or forwarder you selected.
                        • Claims are settled directly by claim adjusters, not employees of the van line or the
                        forwarder.
                        • Insurance covers damage or loss from causes such as Acts of God, hurricanes, high winds,
                        tornadoes, and earthquakes, riots, strikes, civil commotion, fire, theft, etc.
                        • Shippers receive a “Certificate of Insurance” outlining all provisions, stipulations and
                        exclusions.
                        • Insurance is usually less expensive, even with these added benefits.
                        • If your household goods go into storage, insurance includes, at no additional cost, coverage
                        for a
                        period of time, usually up to 90 days. If your storage period exceeds 90 days it is possible to
                        obtain extension coverage, at a nominal cost.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>What are the advantages of insuring with Baker International?</Accordion.Header>
                    <Accordion.Body>
                        • Baker is one of the most respected names in movers` insurance in the moving industry.

                        • For over 25 years, Baker has been providing insurance and first class claim service for
                        relocating
                        families.

                        • Insurance Policies are underwritten by AM BEST A++ Superior Insurance Companies.

                        • If your household goods go into storage, policies include, at no additional cost to you,
                        coverage
                        for up to 90 days. See Certificate for specific details.

                        • Of all the many benefits derived from using the Baker program, the claim service is the most
                        important. You know a professional claim adjuster is processing your claim in an expedient and
                        professional manner.

                        • Claims are adjusted in a consistent manner, regardless of the van line or forwarder providing
                        the
                        transportation service.

                        • Baker International Insurance Agency is able to recognize patterns of loss and damage and
                        thereby
                        offer recommendations to the transporting company on how to prevent future loss or damage.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>What is mover/carrier liability?</Accordion.Header>
                    <Accordion.Body>
                        Carrier liability varies from company to company.

                        • Common Carriers (companies who generally transport freight) release themselves at $0.10 per
                        pound
                        per article unless otherwise specified.

                        • Moving and storage companies release themselves at $0.60 per pound per article – as required,
                        based on DOT regulations or State requirements. This level protects the moving company from
                        having
                        to pay out the actual value of an article due to loss or damage, for which they may be liable.
                        It
                        allows them to pay a minimal amount on damage or catastrophic loss based entirely on the weight
                        of
                        the item contained within the household goods shipment.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>How soon before my move date may I purchase the full replacement household goods
                        transit insurance?</Accordion.Header>
                    <Accordion.Body>
                        It is best to purchase your full replacement insurance policy soon after you have decided on a
                        mover. To avoid an additional rush fee make sure you have purchased insurance at least 48 hours
                        before your pack/move date.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Can my request for full replacement household goods transit insurance be
                        denied?</Accordion.Header>
                    <Accordion.Body>
                        Your request for insurance may be declined if your household goods are coming out of a storage
                        unit
                        or if there is not enough time to issue a signed certificate and collect premium payment before
                        movers arrive for packing or loading.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>What if I change the move date?</Accordion.Header>
                    <Accordion.Body>
                        If you decide to change your move date, contact Baker International to note the change at least
                        24
                        hours before current move date.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Do I need to complete a full replacement household goods valued
                        inventory?</Accordion.Header>
                    <Accordion.Body>
                        If you are moving within the continental United States we require an inventory on fine arts and
                        antiques valued at $5,000 or more and on Collection items valued over $500.00. A complete valued
                        inventory is necessary if you are moving to Alaska, Hawaii, or any other place in the world.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>What is Co-Insurance?</Accordion.Header>
                    <Accordion.Body>
                        It is the condition of this transit policy to insure 100% of the value of the household goods
                        shipment. If 100% of the value is not declared the owner will bear a proportionate share of any
                        covered loss or damage that may arise while in transit/storage.

                        For example, if the actual household goods shipment has a value of $50,000 and you decide to
                        declare
                        it for $25,000, the insurance policy will only cover 50% of the loss or damage, as the goods
                        were
                        insured for only one-half of their actual value.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>What is considered “high value”?</Accordion.Header>
                    <Accordion.Body>
                        High value is considered to be any one item over $5000.00 in value. You are required to provide
                        us
                        with a specific line item identification and value for each such line item.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>Can I purchase Full Replacement insurance for specific items?</Accordion.Header>
                    <Accordion.Body>
                        The full value replacement policy covers 100% of all goods shipped. The policy may not be
                        limited to
                        a few special items contained within the shipment.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Can I insure my automobile in transit?</Accordion.Header>
                    <Accordion.Body>
                        Baker International will cover your vehicle in transit with a mover, as long as the vehicle is
                        contained within the transporting vehicle containing the household goods shipment, or is
                        transported
                        on the transporting company’s car carrier. If the vehicle is sub-contracted to an independent
                        car
                        hauling company coverage will not apply. The value of the shipment will be the combined value of
                        the
                        vehicle and the household goods within the truck.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header>What if I subtract or add items to my household goods descriptive or valued
                        inventory?</Accordion.Header>
                    <Accordion.Body>
                        If a policy has been issued please contact Baker International to confirm the addition or
                        subtraction of an item. This change may effect the coverage and.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                    <Accordion.Header>What items are not covered under the household goods transit
                        policy?</Accordion.Header>
                    <Accordion.Body>
                        Items over $5000.00 in value, unless otherwise declared on the high value inventory. Collections
                        over $500.00 in value, unless otherwise declared on the high value inventory.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>What is a deductible?</Accordion.Header>
                    <Accordion.Body>
                        A deductible is that portion of loss or damage the owner assumes. The deductible amount will be
                        subtracted from the approved claim reimbursement, if there is a claim settlement.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the deductible options on my household goods insurance
                        policy?</Accordion.Header>
                    <Accordion.Body>
                        It is best to contact Baker International to negotiate the most appropriate deductible and
                        coverage for your household goods and personal effects in transit.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>How much will the household goods transit insurance cost?</Accordion.Header>
                    <Accordion.Body>
                        It is best to contact Baker International sales department to get an accurate quote based on the
                        value of your household goods and personal effects.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>If I pack my own boxes, are the contents covered against
                        damage?</Accordion.Header>
                    <Accordion.Body>
                        The policy only covers contents in boxes packed by the mover. If you choose to pack your own
                        boxes, contents are not covered unless the container is damaged and noted on the van lines’ or
                        forwarders delivery documents (i.e., the Bill of Lading, the Descriptive Inventory, or the
                        “Bingo sheet”).
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>If my household goods store in a mini warehouse/self-storage unit, will the policy
                        continue coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                        When your household goods go into a mini warehouse/self- storage unit it is considered to be in
                        your care and custody, and no longer is covered under the transit insurance policy.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Will the policy cover mold and mildew?</Accordion.Header>
                    <Accordion.Body>
                        When household goods are in transit, they generally do not remain stationary long enough to be
                        exposed to mold or mildew. Mold and mildew are excluded in this transit policy.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>How do I file a damage claim?</Accordion.Header>
                    <Accordion.Body>
                        Go to the web site and download a claim form or contact Baker International at 940-270-3200 and
                        request a claim form.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>How long do I have to file a damage claim?</Accordion.Header>
                    <Accordion.Body>
                        Residential moves must be submitted to the Baker International claims department 90 days from
                        the date of delivery.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>How does the claim process progress?</Accordion.Header>
                    <Accordion.Body>
                        Claim form received by Baker from customer.

                        Claim adjuster assigned and confirmation letter sent to customer.

                        Quality control manager contacts customer by telephone to answer questions and set customer
                        expectations.


                        Baker has access to hundreds of active appraisers, craftsman, and repair firms to assist with on
                        site inspection and/or repair at customer`s residence.


                        Claim adjusters operate with a Daily Diary to make certain our goal to settle a claim within 30
                        days of receipt of each claim is maintained.


                        Each Claim Adjuster has an individual toll free number.


                        Quality Control Manager monitors progress of each claim to make certain appraisers, craftsman,
                        repair firms and claim adjusters expedite the settlement process.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>How long does it take to settle my claim?</Accordion.Header>
                    <Accordion.Body>
                        Claim adjusters operate with a Daily Diary to make certain our goal to settle a claim within 30
                        days of receipt of each claim is met.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>How can I purchase household goods transit insurance?</Accordion.Header>
                    <Accordion.Body>
                        Contact Baker International Insurance at 800-356-0099 and speak with a sales coordinator to
                        assist in determining the value of your household goods, review deductible options, and be
                        provided a quote on your moving insurance premium.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>How do I get my household goods Full Replacement Value Certificate of
                        Insurance?</Accordion.Header>
                    <Accordion.Body>
                        A certificate of insurance will be mailed within 24 hours of purchasing moving insurance.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}
