import './Pages.css'
import BannerImage from "../BannerImages/BannerImage";
import Footer from "../Footer/Footer";
import React from "react";
import TestimonyCarousel from "../Carousel/TestimonyCarousel";

export default function Home() {
    return (
        <div className={"main-content"}>
            <BannerImage/>
            <div className={"sub-content"}>
                <hr/>
                <h2 className={"heading-title"}>With Over 40 Years of Claim Settling Expertise...</h2>
                <p>
                    Since 1981, Baker International Insurance has been a best-in-class provider of all-risk worldwide
                    transit insurance, specializing in domestic and international household goods. We have extensive
                    experience partnering directly with Corporations and Relocation Management Companies creating
                    innovative
                    programs to meet the insurance needs of their relocating populations and effectively managing
                    relocating
                    risks. At Baker, we know insurance coverage is only as good as the claim service provided. With over
                    40
                    years of claim setting expertise to quickly and fairly settle claims, our claim settlement service
                    is
                    unparalleled in the industry.
                </p>
                <a href={"/about"} className={"content-btn"}>Learn More</a>
                <hr/>
                <h3 className={"heading-title"}>Our Services</h3>
                <div className={"tile-container"}>
                    <div>
                        <img src={"/domain-registration.png"} alt={"Corporate Relocation icon"} width={90}/>
                        <p className={"tile-title"}>Corporate Relocation</p>
                        <p>
                            Baker International is the premier provider of transit insurance for domestic &
                            international household goods serving the Corporate Relocation industry.
                        </p>
                        <button><a href={"/corporate"} className={"content-btn"}>Learn More</a></button>
                    </div>
                    <div>
                        <img src={"/viral-marketing.png"} alt={"Residential/Homeowner icon"} width={90}/>
                        <p className={"tile-title"}>Residential/Homeowner</p>
                        <p>Have a "No Regret" Move. Baker International provides the most competitive rate and the
                            broadest coverage.</p>
                        <button><a href={"/residential"} className={"content-btn"}>Learn More</a></button>
                    </div>
                    <div>
                        <img src={"/certificate.png"} alt={"Commercial/Office icon"} width={90}/>
                        <p className={"tile-title"}>Commercial/Office</p>
                        <p>Providing quality insurance protection for high-value electronics, office, and industrial
                            relocations.</p>
                        <button><a href={"/corporate"} className={"content-btn"}>Learn More</a></button>
                    </div>
                    <div>
                        <img src={"/startup.png"} alt={"Claim Service icon"} width={90}/>
                        <p className={"tile-title"}>Claim Service</p>
                        <p>Claim service is the foundation of Baker’s commitment to its customers. We sell insurance and
                            service claims. Claims are settled "in-house", never outsourced!</p>
                        <button><a href={"/claimService"} className={"content-btn"}>Learn More</a></button>
                    </div>
                </div>
                <h4 className={"heading-title"}>Our Core Business</h4>
                <div className={"tile-container-2"}>
                    <img src={"/home-img-1.png"} alt={"Core Business"} className={"content-image"} width={350}/>
                    <p>Providing insurance protection to household goods while in transit for over 40 years. Our
                        policies, coupled with the finest claim service available have positioned Baker International as
                        an industry leader. Our door-to-door insurance protection program is designed for exposure of
                        transferring employees’ household belongings at all stages of a move including initial packing,
                        loading, while in transit, storage, unloading, and unpacking. Baker International’s insurance
                        protection follows the goods with the same consistent coverage throughout the move, including
                        permanent storage and the final move out of storage.</p>
                </div>
                <hr/>
                <h5 className={"heading-title"}>Testimonials</h5>
                <TestimonyCarousel />
                <hr/>
                <h6 className={"heading-title"}>Get in touch with us.</h6>
                <p>If you have any questions or queries a member of staff will always be happy to help. Feel free to
                    contact us by telephone or email we will be sure to get back to you as soon as possible.</p>
                <p className={"p-bolded"}>Address</p>
                <p>510 E Corporate Dr. Suite 300 Lewisville, TX 75057</p>
                <p className={"p-bolded"}>Phone</p>
                <p>Main (800) 356-0093<br/>
                    or (940) 270-3200<br/>
                    Claims Fax (940) 269-1503<br/>
                    Sales Fax (940) 270-1500</p>
                <p className={"p-bolded"}>Email</p>
                <p>
                    Info@bakerintl.com<br/>
                    Claims@bakerintl.com
                </p>
            </div>
            <Footer/>
        </div>
    );
}
