import './Pages.css'
import Footer from "../Footer/Footer";
import React from "react";

export default function About() {
    return (
        <div className={"main-content"}>
            <div className={"paragraph-text"}>
                <h1>About Us</h1>
                <p>Baker International was founded in 1981 specifically to serve the insurance needs of the Corporate
                    Relocation industry and unlike some of our competitors, we remain totally focused on our niche.</p>
                <p>We do not provide any other insurance products not related to the Corporate Relocation and assignment
                    services business. We are one of the largest providers exclusively servicing the Relocation industry
                    with extensive experience partnering directly with Corporations and Relocation Management Companies
                    creating innovative programs to meet the insurance needs of their relocating populations and effectively
                    managing relocating risks. We take tremendous pride in being the best at what we do and in proving to
                    our clients day in and day out just how much we value their business.</p>
                <p>You and your transferees will receive the critical ongoing attention needed to ensure a successful
                    household goods protection program. When you partner with Baker International not only are you receiving
                    superior transit insurance coverage coupled with unparalleled claims settlement expertise, but you will
                    also benefit from working with an organization that understands the complexities of navigating transit
                    insurance within the Relocation industry.</p>
            </div>
            <Footer/>
        </div>
    );
}
