import {GenericBannerImage} from "../BannerImages/GenericBannerImage";
import Footer from "../Footer/Footer";
import TestimonyCarousel from "../Carousel/TestimonyCarousel";

export default function Residential() {
    return (
        <div className={"main-content"}>
            <GenericBannerImage message={"Residential"}/>
            <hr/>
            <h2 className={"heading-title text-center"}>Welcome to Baker International, our online feature that will
                allow you to purchase insurance at your convenience.</h2>
            <div className={"sub-content"}>
                <p className={"alternate-text"}>Before attempting to purchase insurance you will need several items to get started. This information
                    is provided to you by your moving company representative. You will not be able to proceed online
                    without these two items of information.</p>
                <ol className={"alternate-text bold"}>
                    <li>Your moving company’s identifying registration number</li>
                    <li>The weight of your shipment</li>
                </ol>
                <p className={"alternate-text"}>You will go through pages that will require information before you can proceed to the next page
                    ultimately ending up with the ability to purchase insurance.</p>
                <p style={{fontSize: "14px"}}>Contact Baker International Insurance with any questions or concern during our normal working hours
                    at 800-356-0093 Monday thru Friday 8:00 a.m. to 5:00 p.m. CST except holidays, or anytime via email
                    at <a href={"mailto:info@bakerintl.com"}>info@bakerintl.com</a>. You may also visit our FAQ page.</p>
                <p style={{fontSize: "14px"}}>To begin the process of purchasing transit insurance for your Household Goods click “Purchase
                    Insurance”.</p>
                <a className={"content-btn"} href={"https://www.bakerintl.com/quote/"} target="_blank" rel="noreferrer">PURCHASE INSURANCE</a>
                <h3 className={"heading-title"}>Testimonials</h3>
                <TestimonyCarousel />
            </div>
            <Footer />
        </div>
    );
}
