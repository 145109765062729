import './BannerImage.css'
import img from './banner-image-2.jpg'

type BannerImageProps = {
    message: String
}

export const GenericBannerImage = ({message}: BannerImageProps) => {
    const imgStyle = {
        backgroundImage: `linear-gradient(270deg, rgba(122, 122, 122, .4), rgba(25, 38, 115, .70)), url(${img})`
    }
    return (
        <div style={imgStyle} className={"banner-container"}>
            <div className={"banner-text"} style={{textAlign: "center"}}>
                <h1>{message}</h1>
            </div>
        </div>
    );
}