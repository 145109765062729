import './BannerImage.css'
import img from './home-image.jpg'

function BannerImage() {
    const imgStyle = {
        backgroundImage: `linear-gradient(270deg, rgba(122, 122, 122, .4), rgba(25, 38, 115, .70)),
    url(${img})`
    }
    return (
        <div style={imgStyle} className={"banner-container"}>
            <div className={"banner-text"}>
                <p>Let Our Experts Help You</p>
                <h1>Baker International<br/>Insurance Agency</h1>
                <p>We are the premier provider of transit insurance for domestic & international household goods serving
                    the Corporate Relocation industry since 1981.</p>
                <button className={"primary-btn"}>
                    <a href={"https://claims.bakerintl.com"} target="_blank"
                       rel="noreferrer">File a Claim</a>
                </button>
                <button className={"secondary-btn"}>
                    <a href={"https://remote.bakerintl.com/quote/"}
                       target="_blank" rel="noreferrer">Get a Quote or Purchase Insurance</a>
                </button>
            </div>
            <div></div>
        </div>
    );
}

export default BannerImage;
