import {GenericBannerImage} from "../BannerImages/GenericBannerImage";
import Footer from "../Footer/Footer";

export default function Quote() {
    return (
        <div className={"main-content"}>
            <GenericBannerImage message={"Get a Quote"}/>
            <hr/>
            <div className={"sub-content"}>
                <b>Baker International Insurance Online COD Ordering</b>
                <p>Before you continue, please note this is a “Quick Estimate Tool” only, and is based on the dollar
                    value you enter into this website. This tool is provided so you will not have to go through the
                    entire information gathering process to get a cost estimate. The amount you pay may be different
                    when you go through the actual purchasing process due to minimum weights and other information
                    entered during completion of online forms.</p>
                <p>This policy contains a coinsurance clause. If you purchase insurance, you must insure to the
                    replacement value of the total shipment. (For example, in the case of a total loss situation, i.e. a
                    fire, how much it would cost to replace the entire shipment.)</p>
                <p>If you know your mover at this time, <b>you must pick it from the drop down</b> that appears in order
                    to proceed with your online purchase. Do not move from the first blank until you have selected your
                    mover's name.</p>
                <p>If you do not know your mover or cannot find your mover in the drop-down list, give us a call
                    at <b>1-800-356-0099</b>.</p>
                <a href={"https://remote.bakerintl.com/quote/"}
                   target="_blank" className={"content-btn"} rel="noreferrer">Get Quick Quote</a>
            </div>
            <Footer/>
        </div>
    );
}
