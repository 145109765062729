import {GenericBannerImage} from "../BannerImages/GenericBannerImage";
import TestimonyCarousel from "../Carousel/TestimonyCarousel";
import Footer from "../Footer/Footer";

export default function ClaimService() {
    return (
        <div className={"main-content"}>
            <GenericBannerImage message={"Claim Service"}/>
            <hr/>
            <div className={"sub-content"}>
                <h2 className={"heading-title"}>Baker International Insurance is here to help when you need it.</h2>
                <ul>
                    <li>Claim must be filed within 90 days after your shipment was delivered.</li>
                    <li>Please do not discard or repair any damaged items prior to conclusion of claim.</li>
                    <li>You may submit your claim online at <a href={"https://claims.bakerintl.com"} target="_blank" rel="noreferrer">claims.bakerintl.com</a> or you can follow
                        the below instructions to complete the Statement of Claim Form and send to
                        Baker International <a href={"mailto:laims@bakerintl.com"}>claims@bakerintl.com</a></li>
                </ul>
                <h3 style={{paddingTop: "20px"}}>Use the 'Online Claim Submission Form' to submit your claim.</h3>
                <a className={"content-btn"} href={"https://claims.bakerintl.com"} target="_blank" rel="noreferrer">FILE CLAIM</a>
            </div>
            <Footer />
        </div>
    )
}
