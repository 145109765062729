import "./Pages.css"
import {GenericBannerImage} from "../BannerImages/GenericBannerImage";
import {Accordion} from "react-bootstrap";
import React from "react";
import Footer from "../Footer/Footer";

export default function Corporate() {
    return (
        <div className={"main-content"}>
            <GenericBannerImage message={"Corporate/RMC"}/>
            <div className={"sub-content"}>
                <hr/>
                <h2 className={"heading-title text-center"}>Insuring the Corporate Relocation Industry Since 1981</h2>
                <ul>
                    <li>Domestic Household Goods Transit Insurance</li>
                    <li>International Household Goods Transit Insurance</li>
                    <li>Vacant Dwelling Insurance</li>
                </ul>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Household Goods Transit Insurance</Accordion.Header>
                        <Accordion.Body>
                            Mobility program and relocation policy design is a challenging undertaking, especially given
                            the complexities in attempting to address the individual needs of the various segments of
                            your relocating population. A critical area often overlooked during program and policy
                            design and after navigating through all of the major service components is household goods
                            coverage protecting the employee should something happen while the goods are in transit or
                            storage.


                            This is where the Baker transit coverage becomes such a critical component of your overall
                            relocation offering. Our replacement cost coverage provides the best vehicle for assuring
                            your transferees household goods are protected with optimal coverage in the event of damaged
                            or missing items or a catastrophic loss scenario. The Baker coverage provides protection and
                            peace of mind for your transferees during the incredibly stressful event of relocating and
                            transporting their most important possessions to the new location.


                            In addition to the transit coverage you will be provided with the most comprehensive
                            reporting data available within the transit insurance industry. Our standard reporting
                            package allows for the monitoring and measuring of ongoing individual carrier and account
                            performance, equipping your organization with performance related data to assist with
                            supplier management and oversight.


                            At Baker we take a consultative approach when speaking with prospective clients to truly
                            understand their current program structure & design and to identity any opportunities that
                            may exist to maximize program coverages and efficiency. If you are interested in obtaining
                            household goods transit coverage for your relocation program, please contact us today for
                            specific coverage and rate information.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Vacant Dwelling Insurance</Accordion.Header>
                        <Accordion.Body>
                            Insuring a vacant home is a particularly troublesome aspect of any move, as the majority of
                            domestic insurers, in most jurisdictions, are reluctant to cover vacant dwellings. In some
                            cases, limited coverage is
                            provided. In most cases, domestic insurance companies do not provide coverage if your home
                            remains unoccupied. The potential for a serious financial loss to the transferee is
                            enormous.

                            Baker is keenly aware of the dilemma faced by transferees who are understandably reluctant
                            to leave their homes behind when they relocate. We are able to provide insurance coverage
                            for vacant principal dwellings located anywhere in the United States or Canada.

                            The coverage afforded protects against most losses, including earthquake and flood (with
                            somewhat higher stipulated deductibles). Contents are protected up to a maximum of $25,000.
                            The policy provides up to 10% of the dwelling value to protect other buildings on the
                            property. And finally, the policy is capable of providing protection for liability suits
                            brought for damages arising from the ownership,
                            maintenance or use of the vacant dwelling, including defense and medical expense coverage.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <h3 className={"heading-title"}>Have more questions?</h3>
                <p>If you have any questions or queries a member of staff will always be happy to help. Feel free to
                    contact us by telephone or email we will be sure to get back to you as soon as possible.</p>
                <p className={"p-bolded"}>Address</p>
                <p>510 E Corporate Dr. Suite 300 Lewisville, TX 75057</p>
                <p className={"p-bolded"}>Phone</p>
                <p>Main (800) 356-0093<br/>
                    or (940) 270-3200<br/>
                    Claims Fax (940) 269-1503<br/>
                    Sales Fax (940) 270-1500</p>
                <p className={"p-bolded"}>Email</p>
                <p>
                    Info@bakerintl.com<br/>
                    Claims@bakerintl.com
                </p>
            </div>
            <Footer />
        </div>
    );
}