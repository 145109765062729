import './Footer.css'
import {NavLink} from "../HeadersNav/NavBarElements";
import React from "react";

export default function Footer() {
    return (
        <footer>
            <div className={"footer-content"}>
                <p>510 E Corporate Dr. Suite 300<br/>
                    Lewisville, Texas 75057
                </p>
                <nav>
                    <b>Quick Links</b>
                    <NavLink to='/'>
                        Home
                    </NavLink>
                    <NavLink to='/about'>
                        About Us
                    </NavLink>
                    <NavLink to='/corporate'>
                        Corporate/RMC
                    </NavLink>
                    <NavLink to='/residential'>
                        Residential/Homeowner
                    </NavLink>
                </nav>
                <p>
                    <b>Main</b><br/>
                    800.356.0093 or 940.270.3200<br/>
                    <b>Claims Fax</b><br/>
                    940.269.1503<br />
                    <b>Sales Fax</b><br/>
                    940.270.1500
                </p>
            </div>
        </footer>
    )
}