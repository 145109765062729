import './SuperHeader.css'

function SuperHeader() {
    return (
        <div className={"header"}>
            <img width={150} src={"/BAKER-Blue-From-Tif-s.png"} alt={"Company Logo"}/>
            <h1 className={"header-title"}>Baker International Insurance Agency</h1>
            <div className={"header-actions"}>
                <a href={"https://remote.bakerintl.com/login.asp?GoTo=%2Fonline%2Easp"} target="_blank" className={"header-button"} rel="noreferrer">Corporate Login</a>
                <a href={"https://claims.bakerintl.com"} target="_blank" rel="noreferrer" className={"header-button"}>File a Claim</a>
            </div>
        </div>
    )
}


export default SuperHeader;
