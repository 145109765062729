import React from 'react';
import './App.css';
import SuperHeader from "./components/HeadersNav/SuperHeader";
import MainNavigation from "./components/HeadersNav/MainNavigation";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import 'bootstrap/dist/css/bootstrap.min.css';
import Faq from "./components/pages/Faq";
import Corporate from "./components/pages/Corporate";
import Residential from "./components/pages/Residential";
import ClaimService from "./components/pages/ClaimService";
import Quote from "./components/pages/Quote";

function App() {
  return (
    <>
      <SuperHeader />
        <BrowserRouter>
            <MainNavigation />
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={"/about"} element={<About />} />
                <Route path={"/faq"} element={<Faq />} />
                <Route path={"/General/Faq.aspx"} element={<Faq />}/>
                <Route path={"/corporate"} element={<Corporate />} />
                <Route path={"/residential"} element={<Residential />} />
                <Route path={"/claimService"} element={<ClaimService />} />
                <Route path={"/quote"} element={<Quote />} />
                <Route path={"*"} element={<Home />} />
            </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
