import React from 'react';
import {Nav, NavLink, NavMenu, Bars} from './NavBarElements';
import { FaTimes } from "react-icons/fa";

function NavBar() {

    const showNav = () => {
        const navMenu = document.getElementById("navigation-menu");
        if (navMenu != null) {
            navMenu.classList.toggle("responsive-nav");
        }
    }

    return (
        <>
            <Bars onClick={showNav}/>
            <Nav id={"navigation-menu"}>
                <NavMenu>
                    <NavLink to='/' onClick={showNav}>
                        Home
                    </NavLink>
                    <NavLink to='/about' onClick={showNav}>
                        About Us
                    </NavLink>
                    <NavLink to='/faq' onClick={showNav}>
                        FAQ
                    </NavLink>
                    {/*<NavLink to='/resources'>*/}
                    {/*    Resources*/}
                    {/*</NavLink>*/}
                    <NavLink to='/corporate' onClick={showNav}>
                        Corporate/RMC
                    </NavLink>
                    <NavLink to='/residential' onClick={showNav}>
                        Residential/Homeowner
                    </NavLink>
                    <NavLink to='/claimService' onClick={showNav}>
                        Claim Service
                    </NavLink>
                    <NavLink to='/quote' onClick={showNav}>
                        Get A Quote
                    </NavLink>
                </NavMenu>
                {/*<NavBtn>*/}
                {/*    <NavBtnLink to='/claim'>Contact Us</NavBtnLink>*/}
                {/*</NavBtn>*/}
                <button className={"nav-close-btn"} onClick={showNav}><FaTimes/></button>
            </Nav>
        </>
    );
}

export default NavBar;
