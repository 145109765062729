import Carousel from "react-bootstrap/Carousel";
import './Carousel.css'

export default function TestimonyCarousel() {
    return (
        <Carousel variant="dark" controls={false}>
            <Carousel.Item>
                <p>
                    My claims adjuster was incredibly proactive. One of the pieces damaged in my move was a family
                    heirloom credenza. The adjuster made all arrangements with a professional restoration company and I
                    was amazed that after completion of the repairs I couldn't even tell it had been damaged. So
                    Incredibly appreciative of the swift support from Baker in resolving my claim.
                </p>
                <Carousel.Caption>
                    <h3>MICHELLE</h3>
                    <p>TEXAS</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <p>

                    Literally everything we own was destroyed in transit when the truck caught on fire. Our Baker claims
                    adjuster was phenomenal in assisting us through one of the more difficult times in our lives and
                    what was truly a catastrophic situation. We were beyond thankful for the insurance coverage on our
                    move and that my employer had chosen to
                    utilize Baker International.
                </p>
                <Carousel.Caption>
                    <h3>STEVE & KAREN</h3>
                    <p>CALIFORNIA</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <p>
                    The partnership with Baker and the insurance product has most definitely increased overall
                    satisfaction for my relocating employees specific to their household goods and moving experience.
                    Baker is an amazing partner.
                </p>
                <Carousel.Caption>
                    <h3>CORPORATE GLOBAL MOBILITY</h3>
                    <p>MANAGER</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <p>
                    Having a standardized 3rd party insurance provider in place is absolutely key in terms of accurately
                    assessing ongoing carrier performance within our supplier network. Baker does a phenomenal job of
                    arming me with that performance-related data.
                </p>
                <Carousel.Caption>
                    <h3>RMC SUPPLY CHAIN</h3>
                    <p>SVP</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}
