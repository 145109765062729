import AccordionFaq from "../Accordion/AccordionFaq";
import "./Pages.css"
import {GenericBannerImage} from "../BannerImages/GenericBannerImage";

export default function Faq() {
    return (
        <div className={"main-content"}>
            <GenericBannerImage message={"FAQ"} />
            <hr/>
            <h2 className={"heading-title text-center"}>Frequently Asked Questions</h2>
            <div className={"sub-content accordion-content flex-row"}>
                <AccordionFaq />
            </div>
        </div>
    );
}